import { OperationVolume } from './../../_models/operation';
import { createAction, props} from "@ngrx/store";
import { NewVolumeSize } from "./../../_models/newVolumeSize"
import { TypeOperation } from "src/_models/typeOperation";
import {VolumePack} from "../../_models/volumePack"
export const setNewVolumeSize = createAction('[main component], setNewVolumeSize', props<{newVolumeSize:NewVolumeSize}>());

export const setTypeOperation = createAction('[main component], setTypeOperation',props<{typeOperation:TypeOperation}>());

export const setOperationVolume = createAction('[main component], setOperationVolume',props<{operationVolume:OperationVolume}>());

export const setVolumePack = createAction('[main component], setVolumePack',props<{volumePack:VolumePack}>());
