import { Layer, LayerGroup } from 'leaflet';
import { GeometryInterface } from './../../../_models/geometry.model';
import { geometryType } from './../../../_types/types';
import { TileHelper } from './tile-helper';

declare var L: any;
export class GeometrieHelper extends TileHelper {
    globalGeometry: Map<geometryType, GeometryInterface> = new Map
    geometryOnMap: LayerGroup 

    
    updateGlobalGeometry(type: geometryType, layer: Layer, id: any, showOnMap: boolean = true){
        let geometries: GeometryInterface  = this.globalGeometry.get(type) || { 
            layer:  new L.featureGroup([]),
            layerId: 0,
            layers: new Map()
        }
        geometries.layer.addLayer(layer)
        geometries.layers.set(id, geometries.layer.getLayerId(layer))
        this.globalGeometry.set(type, geometries)

        // update map 
        if(showOnMap){
            this.addGeometryOnMap(type)
        }
      
    }

    updateGlobalGeometryParceliseBloc(type: geometryType, layer: Layer, id: any, showOnMap: boolean = true){
        let geometries: GeometryInterface  = this.globalGeometry.get(type) || { 
            layer:  new L.featureGroup([]),
            layerId: 0,
            layers: new Map()
        }
        geometries.layer.addLayer(layer)
        geometries.layers.set(id, geometries.layer.getLayerId(layer))
        this.globalGeometry.set(type, geometries)
        if(showOnMap){
            this.addGeometryOnMap(type)
        }

    }

    removeLayerFromGlobalGeoetry(type:geometryType, id:any){
        let geometries: GeometryInterface  = this.globalGeometry.get(type) as GeometryInterface
        console.log('geometrie to delete', geometries)
        if(geometries){
            const layerId= geometries.layers.get(id)
            if(layerId){
                geometries.layer.removeLayer(layerId)
                this.globalGeometry.set(type, geometries)
            }
        }
    }

    clearGlobalGeometrie(type: geometryType){
    
      let geometries = this.globalGeometry.get(type)
      if(geometries && this.geometryOnMap.hasLayer(geometries.layer)){
        this.geometryOnMap.removeLayer(geometries?.layerId)
        geometries.layer.clearLayers()
        geometries.layers = new Map()
        geometries.layerId = 0
        this.globalGeometry.set(type, geometries)
      }
    }

    removeFromGeometrie(type: geometryType){
       let geometries = this.globalGeometry.get(type)
       
        if(geometries){
            if(this.geometryOnMap.hasLayer(geometries.layer)){
                  this.geometryOnMap.removeLayer(geometries.layerId)
            }
          
        }
    }

    clearFromGeometrie(type: geometryType){
        
        let geometrie = this.globalGeometry.get(type)
        if(geometrie && geometrie.layerId){
            try {
             this.geometryOnMap.removeLayer(geometrie.layerId)
            } catch (error) {
                console.log('removing geometry', error)
            }
            this.globalGeometry.delete(type)
        }
    }

    softClearGeometries(){
        this.removeFromGeometrie('contourLine')
        this.removeFromGeometrie('contourPoint')
        this.removeFromGeometrie('protectedZone')
        this.removeFromGeometrie('plotPoint')
        this.removeFromGeometrie('plotLine')
        this.addGeometryOnMap('noPopUpProtectedZone')
    }


    addGeometryOnMap(type: geometryType){

         let geometry = this.globalGeometry.get(type) 
        if(geometry){
            this.geometryOnMap.addLayer(geometry.layer)
            geometry.layerId = this.geometryOnMap.getLayerId(geometry.layer)
            this.globalGeometry.set(type, geometry)
            this.map.addLayer(this.geometryOnMap)
        }
       

    }

    centerMapOn(type: geometryType){
        const geometry = this.globalGeometry.get(type)
        if(geometry){
            this.map.fitBounds(geometry.layer.getBounds())
        }
    }

    

    
}
