import {NgModule} from '@angular/core';
import { APOLLO_OPTIONS, NamedOptions, APOLLO_NAMED_OPTIONS } from 'apollo-angular';
import {ApolloClientOptions, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import { environment } from 'src/environments/environment';
const uri = environment.uri; // <-- add the URL of the GraphQL server here
export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  return {
    link: httpLink.create(
      {
        uri: uri,
        method: 'POST'
      }
      ),
    cache: new InMemoryCache({
      typePolicies: {
        ActeursListType:{
            keyFields: ["results"], 
        },
        VarieteParCultureListType: {
          keyFields: ["results"]
        },
        CultureListType: {
          keyFields: ["results"]
        },
        ZonesProtegeesListType: {
          keyFields: ["results"]
        }
      },
      resultCaching: false
})
}}

export function createTreeApollo(httpLink: HttpLink): NamedOptions {
  return {
   autoTree: {
    link: httpLink.create(
      {
        uri: environment.treesUrl,
        method: 'POST'
      }
      ),
    cache: new InMemoryCache()
   }
}}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: createTreeApollo,
      deps: [HttpLink]
    }
  ],
})
export class GraphQLModule {}
