
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { reducers } from '@src/_contants/store.reducers';
import { Observable } from 'rxjs';
import { Language } from 'src/_enums/language.enum';
import { UtilsService } from 'src/_services/utils.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  selectedLanguage: Language | undefined;
  Language = Language;
  title = 'jool';
  lang$:Observable<any>

  constructor(
    private translateService: TranslateService,
    private utils: UtilsService,
    private store:Store<typeof reducers>
  ){
    this.translateService.init()
    this.translateService.setDefaultLang('fr')
  }
  ngOnInit(): void {
    this.selectedLanguage = <Language>this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (event: LangChangeEvent) => (this.selectedLanguage = <Language>event.lang, console.log(event)) 
    );
  }

  getOutil(){
  }

  get(value:string){
  }

  getValue(buttonValue:boolean){
  }
  
}
