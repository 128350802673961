import { createReducer, on, Store } from "@ngrx/store";
import { Cultures } from "src/_models/culture.interface";
import { setCultures, setSimpleCulture } from "./plots.action";

export interface PlotState{
    cultures: Cultures[],
    simpleCulture: Cultures[],
    
}

export const initialState: PlotState | any = {
     cultures: [], 
     simpleCulture: [],
     }

export const _plotReducer = createReducer(
    initialState,
    on(setCultures, (state, _cultures) => {
        const oldState = JSON.parse(JSON.stringify(state))
        return ({...state, cultures: oldState.cultures.concat(_cultures.cultures)})
    }),
    on(setSimpleCulture, (state, _cultures) => {
        const oldState = JSON.parse(JSON.stringify(state))
        return ({...state, simpleCulture: oldState.simpleCulture.concat(_cultures.cultures)})
    }),
   

)

export function plotReducer(state: PlotState, action: any){
    return _plotReducer(state, action)
}