import {createAction, props } from '@ngrx/store';
import { LatLng } from 'leaflet';
import { RegroupementObjet } from 'src/_models/regroupemenrObjet';

export const updateCounter = createAction('[map Component Component] updateCounter');
export const setTreesNumber = createAction('[map Component] setTreesNumber', props<{trees: []}>())
export const incrementTrees = createAction('[map Component] incrementTrees', props<{coord: LatLng[]}>())
export const decrementTrees = createAction('[counter Component] decrementTrees')
export const setCounts = createAction('[counter Component] setCounts', props<{counts: RegroupementObjet[]}>())

export const addRegroupement = createAction('[counter Component] addRegroupement', props<{regroupement: RegroupementObjet}>())
export const initTress = createAction('[map Facade]');
export const incrementLoadedTrees = createAction('[counter Component] incrementLoadedTrees', props<{trees: object[]}>())
export const setPendingRegroupement = createAction('[counter Component] setPendingRegroupement', props<{regroupement: any}>())
export const initLoadedTrees = createAction('[counter Component] initLoadedTrees')
export const updateTrees = createAction('[counter Component] updateTrees', props<{trees: LatLng[]}>())
