import { HistorigramComponent } from './../shared/components/contour-line-panel/historigram/historigram.component';

import { NgModule } from '@angular/core';
import { RouterModule, Routes, CanActivate } from '@angular/router';
import { AuthGuardService as  AuthGuard } from 'src/_services/auth-guard.service';
import { CircularChartComponent } from '@src/shared/circular-chart/circular-chart.component';

const routes: Routes = [
 
  {
    path : '', loadChildren: () => import('../auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'home', loadChildren: () => import('../main/main.module').then(m => m.MainModule),
    canActivate: [AuthGuard]
  },
  {
    path:'dashboard', loadChildren: () => import('../dahboard/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'histori',
    component: HistorigramComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

