import { setContourlineDataset, setRightDraging } from './../../../_store/map/map.actions';
import { GlobalTileInterface, singleMap, GeometryLayerInterface } from './../../../_models/globalTile.interface';
import { UtilsService } from 'src/_services/utils.service';
import { LeafletHelper } from './leaflet-helper';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { reducers } from 'src/_contants/store.reducers';
import { CountryService } from 'src/_services/api/country.service';
import { MapHelperService } from 'src/_services/map-helper.service';
import { SelectorService } from 'src/_services/selector.service';
import { CartFetch } from 'src/_models/carte';
import { TileMeta } from 'src/_models/tilemeta.model';
import { setCurrentJoolProj, setMapView, showGlobalTile, showTile } from 'src/_store/map/map.actions';
import { environment } from 'src/environments/environment';
import { geometryType, leafletLayer } from 'src/_types/types';
import { Layer, LayerGroup, TileLayer } from 'leaflet';

declare var L: any;
declare var turf: any;
export class TileHelper extends LeafletHelper {
  globalTile: Map<leafletLayer, GlobalTileInterface> = new Map()
  tileOnMap: LayerGroup = L.layerGroup([])
  geometryLayer: Map<geometryType, GeometryLayerInterface> = new Map()
  geometryOnMap: LayerGroup = L.layerGroup([])
  pendingJoolMap: CartFetch
  pendingJoolProj:string ='';
  constructor(
    public translate: TranslateService,
    public store: Store<typeof reducers>,
    public selectorService: SelectorService,
    public countryService: CountryService,
    public mapHelper: MapHelperService,
    public utils: UtilsService
  ) {
    super(translate, store, selectorService, countryService, mapHelper)
  }

  updateGeometryLayer(type: geometryType, layer: Layer){
    let groupLayer = this.geometryLayer.get(type)
    if(groupLayer){
       groupLayer.layer.addLayer(layer)
       groupLayer.layerId = 0
    }else{
      groupLayer = {
        layer:  L.markerClusterGroup([layer]),
        layerId: 0
      }
      groupLayer.layerId = 0
    }
    this.geometryLayer.set(type, groupLayer)
  }

  addGeometryOnMap(type: geometryType){
   let groupLayer = this.geometryLayer.get(type)
   this.geometryOnMap.addLayer(groupLayer?.layer as Layer)
   if(groupLayer){
    groupLayer.layerId = this.geometryOnMap.getLayerId(groupLayer?.layer)
    this.map.addLayer(this.geometryOnMap)
    this.geometryLayer.set(type, groupLayer)
   }
}

 removeGeometryOnMap(type: geometryType){
  let groupLayer = this.geometryLayer.get(type)
  if(groupLayer){
    this.geometryOnMap.removeLayer(groupLayer?.layerId)
  }
 }


  buildTileUrl(type: leafletLayer, map: CartFetch): string{
    let tile
    let mapLink
    switch (type) {
      case 'ortho':
        mapLink = `${environment.file_server}api/img_folder/${map.imgFolder}/tasks/${map.taskIdJpg ? map.taskIdJpg : map.taskIdTiff}`
        tile = mapLink + '/orthophoto/tiles/{z}/{x}/{y}/png'
        break
      case 'health':
        mapLink = `${environment.file_server}api/img_folder/${map.imgFolder}/tasks/${map.taskIdTiff ? map.taskIdTiff : map.taskIdJpg}`
        const formula: string = 'NDVI';
        const band: string = 'RGN';
        tile = mapLink + `/orthophoto/tiles/{z}/{x}/{y}/png?formula=${formula}&bands=${band}&color_map=rdylgn`
        break
      case 'dtm':
        mapLink = `${environment.file_server}api/img_folder/${map.imgFolder}/tasks/${map.taskIdTiff ? map.taskIdTiff : map.taskIdJpg}`
        tile =  mapLink + '/dtm/tiles/{z}/{x}/{y}/png?hillshade=6&color_map=viridis'
        break
      case 'dsm':
        mapLink = `${environment.file_server}api/img_folder/${map.imgFolder}/tasks/${map.taskIdTiff ? map.taskIdTiff : map.taskIdJpg}`
        tile = mapLink + '/dsm/tiles/{z}/{x}/{y}/png?hillshade=6&color_map=viridis'
    }
    return tile as string
  }

  buildTileLayer(
    meta: TileMeta,
    type: leafletLayer,
    map: CartFetch,
    color: string = 'rdylgn'): TileLayer {
    let tile
    const mapSetting = this.mapSetting(meta.bounds)
    switch (type) {
      case 'ortho':
        tile = L.tileLayer(this.utils.buildTile(this.buildTileUrl(type, map), meta),
          mapSetting
        )
        break;
      case 'health':
        tile = L.tileLayer(this.utils.buildTile(this.buildTileUrl(type, map) as string , meta),
          mapSetting
        )
        break
      case 'dtm':
        tile = L.tileLayer(this.utils.buildTile(this.buildTileUrl(type, map) as string, meta),
          mapSetting
        )
        break
      case 'dsm':
        tile = L.tileLayer(this.utils.buildTile(this.buildTileUrl(type, map) as string , meta),
          mapSetting
        )
    }
    return tile
  }

  // Switch global tile
  switchTile(type: leafletLayer, clears: boolean = true) {
    this.pendingJoolProj = ''
    const tileInterface = this.globalTile.get(type) as GlobalTileInterface
    this.store.dispatch(showGlobalTile({ displayTile: true }))
    // On map view updated reload metaData is called in /Users/nafiouadekule/Documents/GitHub/JMonitorPlusFrontEnd/src/shared/components/contour-line-panel/tile-control-panel/tile-control-panel.component.ts
    this.store.dispatch(setMapView({ view: type }))

    if (this.tileOnMap && clears) {
      this.tileOnMap.clearLayers()
    }
    if (tileInterface) {
      this.tileOnMap.addLayer(tileInterface.layer)  

      this.map.addLayer(this.tileOnMap)
      // this.map.addLayer(tileInterface.layer)

      this.fitMapBound(type)
      this.store.dispatch(setRightDraging({show: true}))
      if(type === 'health'){
        const tileInterface = this.globalTile.get('healthBound') as GlobalTileInterface
        if(tileInterface){
          this.tileOnMap.addLayer(tileInterface.layer)
          this.map.addLayer(this.tileOnMap)

          // this.map.addLayer(tileInterface.layer)
        }
      }
    }
  }

  // Switch individual tile
  switchUnique(type: leafletLayer, map: CartFetch) {
    this.store.dispatch(showGlobalTile({ displayTile: false }))
    const tileInterface = this.globalTile.get(type) as GlobalTileInterface
    this.store.dispatch(setMapView({ view: type }))
    if (this.tileOnMap) {
      this.tileOnMap.clearLayers()
    }
   
    if (tileInterface) {
      const tileMap = tileInterface.maps.get(map.id + type)
      let bounds: any[] = [];
      this.tileOnMap.addLayer(tileMap?.layer as Layer)
      this.map.addLayer(this.tileOnMap)
      console.log('fit bound', this.toLeafleBound(bounds.concat(tileMap?.meta.bounds)) )
      this.map.fitBounds(this.toLeafleBound(bounds.concat(tileMap?.meta.bounds)))
     
      if ( type === 'health'){
        const tileInterface = this.globalTile.get('healthBound') as GlobalTileInterface
        const tileMap = tileInterface.maps.get(map.id + 'healthBound')
        this.tileOnMap.addLayer(tileMap?.layer as Layer)
        this.map.addLayer(this.tileOnMap)
      }
    }
  }


  updateGloabalTile(type: leafletLayer, layer: TileLayer, map: CartFetch, meta: TileMeta) {
    let layers = this.globalTile.get(type) as GlobalTileInterface
    meta.bounds = map.bound
    meta.dsmMeta.bounds = map.bound
    meta.dtmMeta.bounds = map.bound
    meta.plantHealthMeta.bounds
    if (layers) {
      layers.layer.addLayer(layer)
    } else {
      layers = {
        layer: L.layerGroup([layer]),
        maps: new Map()
      }
    }

    layers.maps.set(map.id + type, {
      layerId: layers.layer.getLayerId(layer),
      meta: meta,
      layer: layer,
      isOnMap: true
    })
    console.log('set global tile', type, layers)
    this.globalTile.set(type, layers)
    console.log('set global tile', type, layers, this.globalTile)
  }

  toLeafleBound(bounds: any) {
    bounds = bounds.map(
      (item: string) => {
        item = JSON.parse(item)
        return [[item[1], item[0]], [item[3], item[2]]]
      }
    )
    return bounds
  }

  bboxToPolygon(bound: number[]){
     return turf.bboxPolygon(bound)
  }

  async fitMapBound(type: leafletLayer) {
    let mapTile = this.globalTile.get(type) as GlobalTileInterface
    let bounds: any[] = [];
    for (const map of mapTile.maps.values()) {
     
      bounds = bounds.concat(map.meta.bounds)
    }
   
    bounds = this.toLeafleBound(bounds)
    this.map.fitBounds(bounds)
  }

  indiceFunctionHelper(){
    //alert(1)
  }

  addTileToMap(map: CartFetch, meta: TileMeta, clear: boolean = false) {
    
    console.log('addingnew tile', map, meta, clear)
    if (clear) {
      this.store.dispatch(showTile({ state: true })) 
    }

    this.store.dispatch(setCurrentJoolProj({ joolProj: map }))
    if (map.assetsJpg || map.assetsTiff) {
      const orthoLayer = this.buildTileLayer(meta.plantHealthMeta, 'ortho', map)
      const plantHealPhLayer = this.buildTileLayer(meta.plantHealthMeta, 'health', map)
      const dtmLayer = this.buildTileLayer(meta.dtmMeta, 'dtm', map)
      const dsmLayer = this.buildTileLayer(meta.dsmMeta, 'dsm', map)
      
      let plantGeo = L.geoJson(this.bboxToPolygon(JSON.parse(meta.bounds)), 
      {color: 'red',
      fillOpacity: '0',
       opacity: '0'
      }).on(
        'click', () => {
          this.pendingJoolMap = map
        }
      );
     
      
      this.updateGloabalTile('ortho', orthoLayer, map, meta)
      this.updateGloabalTile('health', plantHealPhLayer, map, meta)
      this.updateGloabalTile('healthBound', plantGeo, map, meta)
      this.updateGloabalTile('dtm', dtmLayer, map, meta)
      this.updateGloabalTile('dsm', dsmLayer, map, meta)
    }


    // this.layerControl = L.control.layers(this.baseLayer, this.globaLayer).addTo(this.map);
    if (clear) {
      this.map.fitBounds(meta.bounds)
      // this.switch(MapViews.orthophoto)
    }

  }


  addLayerToMap(layer: any, data: any = null, clean: boolean = false, showOnMap: boolean = true) {
    if (clean) {
      layer.clearLayers()
    }
    if (data) {
      try {
        layer.addLayer(data)
      } catch (error) {
      }
    }
    if (layer && this.map && showOnMap) {
      if (!this.map.hasLayer(layer)) {
        // this.map.removeLayer(layer)
        this.map.addLayer(layer)
      }
    }

  }

  async updateTileAlgo(data: {
    key: string,
    value: string
  }){
    const type = await this.selectorService.currentMapType as leafletLayer
    this.softClearTileOnMap()
    let layer = this.globalTile.get(type as leafletLayer) as GlobalTileInterface
    let newLayerGroup: LayerGroup = L.layerGroup([])
    
    layer?.maps.forEach(
      (value: singleMap | any, Key: string) => {
            if(value?.layer._url){
              value.layer.setUrl(this.utils.buildTile(value?.layer._url, undefined, data))
            }else{
              let tileLayer = value.layer.getLayers()[0]
              tileLayer.setUrl(this.utils.buildTile(tileLayer._url, undefined, data))
            }
            
            newLayerGroup.addLayer(value.layer)
            value.layerId = newLayerGroup.getLayerId(value.layer)
      }
    )
    this.globalTile.set(type, {
      layer: newLayerGroup,
      maps: layer.maps
    })
  }

   updateMeta(metas: Map<string, any>){
    return new Promise(
      async (resolve, reject) => {
        const type = await this.selectorService.currentMapType as leafletLayer
    let layer = this.globalTile.get(type as leafletLayer) as GlobalTileInterface
    let newLayerGroup: LayerGroup = L.layerGroup([])
   
    let layer_size = layer?.maps.size
    if(layer){
      layer?.maps.forEach(
        (value: singleMap | any, key: string) => {
              const meta = metas.get(key.replace(type, ''))
              const currentBound = value.meta.bounds
              if(value?.layer._url){
                value.layer.setUrl(this.utils.buildTile(value?.layer._url, meta, undefined))
              }else{
                 let tileLayer = value.layer.getLayers()[0]
                 tileLayer.setUrl(this.utils.buildTile(tileLayer._url, meta, undefined))
              }
              
              newLayerGroup.addLayer(value.layer)
              value.layerId = newLayerGroup.getLayerId(value.layer)
              value.meta ={ ...meta, bounds: currentBound}
              layer_size -= 1
  
              if(layer_size === 0){
                this.globalTile.set(type, {
                  layer: newLayerGroup,
                  maps: layer.maps
                })
                resolve(true)
              }
        }
      )
    }else{
      reject(false)
    }
   
      }
    )
  }

  async changeTileType(type: leafletLayer){
    const isGlobalTile = await this.selectorService.showGlobalTile
    if(isGlobalTile){
       this.switchTile(type)
    }else{
       const mapIndex = await this.selectorService.pendingMapIndex
       const mapList = await this.selectorService.pendingMapList
       this.switchUnique(type, mapList[mapIndex])
    }
  }

  async addOrRemoveMapFromGlobal(mapId: string){
   const mapType = await this.selectorService.currentMapType as leafletLayer
   let tileLayer = this.globalTile.get(mapType)
   let layer = tileLayer?.maps.get(mapId+mapType)
   if(layer?.isOnMap){
      tileLayer?.layer.removeLayer(layer.layerId)
      layer.isOnMap = false
      tileLayer?.maps.set(mapId+mapType, layer)
   }else{
      tileLayer?.layer.addLayer(layer?.layer as Layer)
   }
   this.switchTile(mapType, false)
  }

  resetControlPanel(){
    this.store.dispatch(setContourlineDataset({dataSets: undefined}))
  }

  clearGeometry(){
   this.geometryOnMap.clearLayers()
  }

  softClearTileOnMap(){
    this.tileOnMap.clearLayers()
  }

  clearGloabalTile(){
    this.tileOnMap.clearLayers()
    this.globalTile.clear()
  }

  addIndiceOnMap(){

  }


}
