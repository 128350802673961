import { appReducer } from "src/_store/app/app.reducer";
import { dashBoardDataReducer } from "src/_store/dashBordData/dashboard.reducer";
import { fileUplaodReducer } from "src/_store/fileUpload/file_upload.reducer";
import { guestListReducer } from "src/_store/guestList/guestList.reducer";
import { setHeaderReducer } from "src/_store/header/header.reducer";
import { counterReducer, treesNumberReducer } from "src/_store/map/count_trees.reducer";
import { mapReducer } from "src/_store/map/map.reducer";

import { operationExistReducer } from "src/_store/operationExist/operationExist.reducer";
import { plotReducer } from "src/_store/plots/plots.reducer";
import { searchReducer } from "src/_store/search/search.reducer";
import { UserProfilReducer } from "src/_store/searchUser/search.reducer";
import { userOperationVolumeReducer } from "src/_store/userOperations/userOperation.reducer";


export const reducers = { 
    count: counterReducer,
    map: mapReducer,
    treesNumber: treesNumberReducer,
    title: setHeaderReducer,
    filesUploaded: fileUplaodReducer,
    plots: plotReducer,
    app: appReducer,
    search: searchReducer,
    UserProfil: UserProfilReducer,
    operationVolume: userOperationVolumeReducer,
    dashBoardData: dashBoardDataReducer,
    operationExistValue:operationExistReducer,
    guestList:guestListReducer,
    

}