import { createReducer, on } from "@ngrx/store";
import { setFilesUploaded} from './file_uplaod.action'
export const initialState:any = null;

const _fileUploadReducer = createReducer(
    initialState,
    on(setFilesUploaded, (state, _files) => ({...state, _files}))
)

export function  fileUplaodReducer(state: any, action: any) {
    return _fileUploadReducer(state, action)
}