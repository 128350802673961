import { state } from '@angular/animations';
import { createReducer, on } from '@ngrx/store'
import { LatLng } from 'leaflet';
import { RegroupementObjet } from 'src/_models/regroupemenrObjet';
import { addRegroupement, decrementTrees, incrementLoadedTrees, incrementTrees, initLoadedTrees, initTress, setCounts, setPendingRegroupement, setTreesNumber, updateCounter, updateTrees } from './count_trees.action'

export interface CounterState {
    trees: LatLng[],
    loaded_trees: any[],
    pendingRegroupement: RegroupementObjet | null
    map: any,
    counts: Map<string, RegroupementObjet> | null
    
}

export const initialState = false;
export const map: CounterState = {trees: [], map: null, counts: null, loaded_trees: [], pendingRegroupement: null};

const _countTreesReducer = createReducer(
    initialState,
    on(updateCounter, (state) => !state)
)

const _treesNumberReducer = createReducer(
    map,
    on(setTreesNumber, (state, _trees) => ({...state, trees: _trees.trees})),
    on(incrementTrees, (state, newCoord) => {
        let initial = JSON.parse(JSON.stringify(state))
        return ({...state, trees: initial.trees.concat(newCoord.coord)})
    }),
    on(
       incrementLoadedTrees, (state, newTrees) => {
        let initial = JSON.parse(JSON.stringify(state))
        return ({...state, loaded_trees: initial.loaded_trees.concat(newTrees.trees)})
       }
    ),
    on(
        decrementTrees, (state) => {
            let initial = JSON.parse(JSON.stringify(state))
            initial.trees.pop()
            return ({...state, trees: initial.trees})
        }
    ),
    on(initTress, (state) => ({...state, trees: []})),
    on(setCounts, (state, _count) => {
        
        let _countMap: Map<string, RegroupementObjet> = new Map();
        
        for(let i=0; i < _count.counts.length; i++){
           _countMap.set(_count.counts[i].id, _count.counts[i])
        }
        return state = ({...state, counts: _countMap})
    }),
    on(addRegroupement, (state, _toAdd) => {
       
        let count = state.counts
        if (count)
        count.set(_toAdd.regroupement.id, _toAdd.regroupement)
        return ({...state, counts: count})
    }),
    on(setPendingRegroupement, (state, _regroupement) => ({...state, pendingRegroupement: _regroupement.regroupement})),
    on(initLoadedTrees, (state) => ({...state, loaded_trees: []})),
    on(updateTrees, (state, _data) => {
        return ({...state, trees: _data.trees})
    })

)

export function  counterReducer(state: any, action: any) {
    return _countTreesReducer(state, action)
}

export function treesNumberReducer(state: any, action: any){
    return _treesNumberReducer(state, action)
}