export class Polygone{
    acteur:string | null;
    id?: string;
    deleted?: boolean;
    kml: string;
    kmlUrl?: string;
    shpUrl?: string;
    kmlDroneUrl?: string;
    geometrie: string;
    shp: string;
    superficie: number;
    centroid: string;
    
    constructor(acteur: string, kml: string, shp: string, geometrie: string, superficie: number,
        centroid: string, ){
        this.centroid = centroid;
        this.acteur = acteur;
        this.kml = kml;
        this.shp = shp;
        this.geometrie = geometrie;
        this.superficie = superficie;
        
    }
}
export interface PolygoneFetch{
     libelle: string;
     createdDate:string
      description: string; 

      id: string;
      createdAt: string;
      typeActeur: {
        id: string,
        libelle: string
      }
      acteurVarieteCulture: {
        id: string
        varietesCultures:{
          id: string
          libelle:string
        }
      }[]
      acteurPoly: {
        geometrie: string
        id: string
        centroid: string
        superficie: number
      }[]
}