import { createReducer, on } from "@ngrx/store";
import { Interface } from "readline";
import { setOperationExistValue } from "./operationExist.action";


export interface setOperationExistState{
    setOperationExist: boolean | null
}

export const initialState: setOperationExistState = { setOperationExist: null}

export const _operationExistReducer = createReducer(
    initialState,
    on(setOperationExistValue, (state, data) => ({...state, setOperationExist: data.value }))
)
export function operationExistReducer(state: any, action: any) { 
    return _operationExistReducer(state, action)
}
