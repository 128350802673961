import { UtilsService } from 'src/_services/utils.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {HttpRequest,HttpHandler,HttpEvent,HttpInterceptor} from '@angular/common/http';
import { environment } from 'src/environments/environment';



@Injectable()
export class TokenInterceptor implements HttpInterceptor{
    constructor(private utilsService:UtilsService){}

    intercept(request:HttpRequest<any>, next:HttpHandler):Observable<HttpEvent<any>>{
        const token = this.utilsService.getStorage('token')
        if(token){
            if(!request.url.includes(environment.fileUploadUrl)){
              request = request.clone({
                setHeaders:{
                    Authorization : `JWT ${this.utilsService.getStorage('token')}`
                }
            });   
          }   
        }else{
            request = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json'
                }
            })
        }
        return next.handle(request); 
    }
}

;
