import { Component, OnInit, Input, ElementRef, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import * as d3 from 'd3';
import { OrthoFormula } from 'src/_contants/orthoFormula';
@Component({
  selector: 'app-historigram',
  templateUrl: './historigram.component.html',
  styleUrls: ['./historigram.component.scss']
})
export class HistorigramComponent implements OnInit, OnChanges {
  @Output() getRescale: EventEmitter<string> = new EventEmitter<string>()
  @Input() statistics: any = [
   {
            "percentiles": [
                -0.15,
                0.15625
            ],
            "min": -0.4166666666666667,
            "max": 0.4634146341463415,
            "std": 0.07433339243115707,
            "histogram": [
                [
                    1.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    1.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    1.0,
                    0.0,
                    1.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    1.0,
                    0.0,
                    0.0,
                    3.0,
                    0.0,
                    1.0,
                    1.0,
                    0.0,
                    1.0,
                    4.0,
                    0.0,
                    1.0,
                    1.0,
                    2.0,
                    1.0,
                    4.0,
                    2.0,
                    6.0,
                    3.0,
                    4.0,
                    6.0,
                    4.0,
                    4.0,
                    5.0,
                    7.0,
                    8.0,
                    11.0,
                    7.0,
                    7.0,
                    14.0,
                    18.0,
                    11.0,
                    13.0,
                    20.0,
                    21.0,
                    27.0,
                    24.0,
                    37.0,
                    46.0,
                    24.0,
                    37.0,
                    42.0,
                    59.0,
                    68.0,
                    67.0,
                    84.0,
                    114.0,
                    102.0,
                    123.0,
                    173.0,
                    195.0,
                    182.0,
                    206.0,
                    270.0,
                    234.0,
                    308.0,
                    404.0,
                    390.0,
                    444.0,
                    482.0,
                    561.0,
                    604.0,
                    573.0,
                    815.0,
                    623.0,
                    942.0,
                    806.0,
                    940.0,
                    1081.0,
                    1274.0,
                    1103.0,
                    1200.0,
                    1479.0,
                    1349.0,
                    1671.0,
                    1373.0,
                    1748.0,
                    1709.0,
                    1874.0,
                    1633.0,
                    1856.0,
                    1942.0,
                    1982.0,
                    1855.0,
                    1987.0,
                    2064.0,
                    1752.0,
                    2085.0,
                    1743.0,
                    2038.0,
                    2023.0,
                    1730.0,
                    1468.0,
                    2498.0,
                    1713.0,
                    190.0,
                    4192.0,
                    8.0,
                    819.0,
                    1848.0,
                    1568.0,
                    1090.0,
                    1176.0,
                    1315.0,
                    1076.0,
                    1232.0,
                    1100.0,
                    1109.0,
                    1065.0,
                    1013.0,
                    980.0,
                    1004.0,
                    780.0,
                    882.0,
                    912.0,
                    687.0,
                    894.0,
                    707.0,
                    538.0,
                    813.0,
                    646.0,
                    551.0,
                    434.0,
                    635.0,
                    456.0,
                    436.0,
                    463.0,
                    368.0,
                    428.0,
                    283.0,
                    291.0,
                    283.0,
                    331.0,
                    204.0,
                    260.0,
                    200.0,
                    255.0,
                    118.0,
                    220.0,
                    149.0,
                    155.0,
                    148.0,
                    137.0,
                    106.0,
                    72.0,
                    117.0,
                    71.0,
                    95.0,
                    90.0,
                    65.0,
                    60.0,
                    71.0,
                    83.0,
                    60.0,
                    58.0,
                    36.0,
                    51.0,
                    47.0,
                    40.0,
                    55.0,
                    45.0,
                    41.0,
                    19.0,
                    22.0,
                    34.0,
                    30.0,
                    31.0,
                    19.0,
                    15.0,
                    25.0,
                    15.0,
                    13.0,
                    21.0,
                    10.0,
                    15.0,
                    13.0,
                    14.0,
                    9.0,
                    8.0,
                    17.0,
                    12.0,
                    8.0,
                    3.0,
                    9.0,
                    7.0,
                    3.0,
                    2.0,
                    8.0,
                    2.0,
                    5.0,
                    1.0,
                    7.0,
                    0.0,
                    9.0,
                    0.0,
                    8.0,
                    0.0,
                    5.0,
                    2.0,
                    6.0,
                    1.0,
                    0.0,
                    3.0,
                    2.0,
                    2.0,
                    0.0,
                    3.0,
                    2.0,
                    0.0,
                    1.0,
                    3.0,
                    3.0,
                    1.0,
                    1.0,
                    1.0,
                    1.0,
                    2.0,
                    0.0,
                    0.0,
                    1.0,
                    0.0,
                    2.0,
                    0.0,
                    1.0,
                    1.0,
                    1.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    1.0
                ],
                [
                    -0.4166666666666667,
                    -0.41321536744779214,
                    -0.4097640682289176,
                    -0.40631276901004304,
                    -0.4028614697911685,
                    -0.399410170572294,
                    -0.39595887135341945,
                    -0.3925075721345449,
                    -0.38905627291567035,
                    -0.3856049736967958,
                    -0.38215367447792126,
                    -0.3787023752590467,
                    -0.37525107604017216,
                    -0.3717997768212976,
                    -0.3683484776024231,
                    -0.36489717838354857,
                    -0.361445879164674,
                    -0.3579945799457995,
                    -0.3545432807269249,
                    -0.3510919815080504,
                    -0.34764068228917583,
                    -0.34418938307030134,
                    -0.3407380838514268,
                    -0.33728678463255224,
                    -0.3338354854136777,
                    -0.33038418619480314,
                    -0.3269328869759286,
                    -0.32348158775705405,
                    -0.3200302885381795,
                    -0.31657898931930495,
                    -0.3131276901004304,
                    -0.3096763908815559,
                    -0.30622509166268136,
                    -0.3027737924438068,
                    -0.29932249322493226,
                    -0.2958711940060577,
                    -0.29241989478718317,
                    -0.2889685955683087,
                    -0.2855172963494341,
                    -0.2820659971305596,
                    -0.278614697911685,
                    -0.2751633986928105,
                    -0.27171209947393593,
                    -0.2682608002550614,
                    -0.26480950103618683,
                    -0.2613582018173123,
                    -0.25790690259843774,
                    -0.2544556033795632,
                    -0.25100430416068864,
                    -0.24755300494181412,
                    -0.2441017057229396,
                    -0.24065040650406505,
                    -0.2371991072851905,
                    -0.23374780806631595,
                    -0.23029650884744143,
                    -0.22684520962856689,
                    -0.22339391040969234,
                    -0.2199426111908178,
                    -0.21649131197194327,
                    -0.21304001275306872,
                    -0.20958871353419417,
                    -0.20613741431531962,
                    -0.2026861150964451,
                    -0.19923481587757055,
                    -0.195783516658696,
                    -0.19233221743982146,
                    -0.1888809182209469,
                    -0.1854296190020724,
                    -0.18197831978319784,
                    -0.1785270205643233,
                    -0.17507572134544874,
                    -0.17162442212657422,
                    -0.16817312290769967,
                    -0.16472182368882515,
                    -0.1612705244699506,
                    -0.15781922525107606,
                    -0.1543679260322015,
                    -0.15091662681332696,
                    -0.1474653275944524,
                    -0.14401402837557786,
                    -0.14056272915670331,
                    -0.13711142993782877,
                    -0.13366013071895427,
                    -0.13020883150007972,
                    -0.12675753228120518,
                    -0.12330623306233063,
                    -0.11985493384345608,
                    -0.11640363462458153,
                    -0.11295233540570698,
                    -0.10950103618683243,
                    -0.10604973696795794,
                    -0.1025984377490834,
                    -0.09914713853020884,
                    -0.0956958393113343,
                    -0.09224454009245975,
                    -0.0887932408735852,
                    -0.08534194165471065,
                    -0.0818906424358361,
                    -0.07843934321696155,
                    -0.07498804399808706,
                    -0.07153674477921251,
                    -0.06808544556033796,
                    -0.06463414634146342,
                    -0.06118284712258887,
                    -0.05773154790371432,
                    -0.05428024868483977,
                    -0.05082894946596522,
                    -0.04737765024709073,
                    -0.04392635102821618,
                    -0.04047505180934163,
                    -0.037023752590467085,
                    -0.03357245337159254,
                    -0.030121154152717988,
                    -0.02666985493384344,
                    -0.02321855571496889,
                    -0.019767256496094343,
                    -0.01631595727721985,
                    -0.012864658058345302,
                    -0.009413358839470753,
                    -0.005962059620596205,
                    -0.0025107604017216567,
                    0.0009405388171528917,
                    0.00439183803602744,
                    0.007843137254901988,
                    0.011294436473776481,
                    0.01474573569265103,
                    0.018197034911525578,
                    0.021648334130400126,
                    0.025099633349274675,
                    0.028550932568149223,
                    0.03200223178702377,
                    0.03545353100589832,
                    0.03890483022477287,
                    0.04235612944364736,
                    0.04580742866252191,
                    0.04925872788139646,
                    0.052710027100271006,
                    0.056161326319145555,
                    0.0596126255380201,
                    0.06306392475689465,
                    0.0665152239757692,
                    0.06996652319464369,
                    0.07341782241351824,
                    0.07686912163239279,
                    0.08032042085126734,
                    0.08377172007014194,
                    0.08722301928901638,
                    0.09067431850789093,
                    0.09412561772676548,
                    0.09757691694564002,
                    0.10102821616451457,
                    0.10447951538338912,
                    0.10793081460226367,
                    0.11138211382113822,
                    0.11483341304001277,
                    0.11828471225888731,
                    0.12173601147776186,
                    0.1251873106966364,
                    0.12863860991551096,
                    0.1320899091343855,
                    0.13554120835326006,
                    0.1389925075721346,
                    0.14244380679100915,
                    0.1458951060098836,
                    0.14934640522875814,
                    0.1527977044476327,
                    0.15624900366650724,
                    0.15970030288538178,
                    0.16315160210425633,
                    0.16660290132313088,
                    0.17005420054200543,
                    0.17350549976087998,
                    0.17695679897975453,
                    0.18040809819862907,
                    0.18385939741750362,
                    0.18731069663637817,
                    0.19076199585525272,
                    0.19421329507412727,
                    0.19766459429300182,
                    0.20111589351187636,
                    0.2045671927307508,
                    0.20801849194962535,
                    0.2114697911684999,
                    0.21492109038737445,
                    0.218372389606249,
                    0.22182368882512354,
                    0.2252749880439981,
                    0.22872628726287264,
                    0.2321775864817472,
                    0.23562888570062174,
                    0.23908018491949629,
                    0.24253148413837083,
                    0.24598278335724538,
                    0.24943408257611993,
                    0.2528853817949945,
                    0.256336681013869,
                    0.2597879802327436,
                    0.263239279451618,
                    0.26669057867049256,
                    0.2701418778893671,
                    0.27359317710824166,
                    0.2770444763271162,
                    0.28049577554599076,
                    0.2839470747648653,
                    0.28739837398373985,
                    0.2908496732026144,
                    0.29430097242148895,
                    0.2977522716403635,
                    0.30120357085923805,
                    0.3046548700781126,
                    0.30810616929698714,
                    0.3115574685158617,
                    0.31500876773473624,
                    0.3184600669536108,
                    0.3219113661724852,
                    0.3253626653913598,
                    0.3288139646102343,
                    0.33226526382910887,
                    0.3357165630479834,
                    0.33916786226685797,
                    0.3426191614857325,
                    0.34607046070460706,
                    0.3495217599234816,
                    0.35297305914235616,
                    0.3564243583612307,
                    0.35987565758010526,
                    0.3633269567989798,
                    0.36677825601785435,
                    0.3702295552367289,
                    0.37368085445560345,
                    0.377132153674478,
                    0.38058345289335244,
                    0.384034752112227,
                    0.38748605133110153,
                    0.3909373505499761,
                    0.39438864976885063,
                    0.3978399489877252,
                    0.4012912482065997,
                    0.4047425474254743,
                    0.4081938466443488,
                    0.41164514586322337,
                    0.4150964450820979,
                    0.41854774430097247,
                    0.421999043519847,
                    0.42545034273872157,
                    0.4289016419575961,
                    0.43235294117647066,
                    0.4358042403953452,
                    0.43925553961421965,
                    0.4427068388330942,
                    0.44615813805196874,
                    0.4496094372708433,
                    0.45306073648971784,
                    0.4565120357085924,
                    0.45996333492746694,
                    0.4634146341463415
                ]
            ],
            "valid_percent": 87.37450935990339
        }
  ]
  @Input() width:  number = 256
  @Input() colorMap: string = 'rdylgn_r'
  @Input() metas: any
  rangeX: any[]
  rangeY: any[]
  hgContainer: any
  colorMapElem: any
  min: number
  max: number
  maxDown: boolean = false;
  minDown: boolean = false;
  defaultBandColors = [
    '#ff0000',
    '#00ff00',
    '#0000ff',
    '#ff8000',
    '#ffff00',
    '#00ff80',
    '#00ffff',
    '#0080ff',
]
  props: {
    width : number,
    colorMap: any,
    onUpdate: null,
    loading: false,
  } = {
    width : 256,
    colorMap: [],
    onUpdate: null,
    loading: false,
  }
  constructor(
    private elRef: ElementRef
  ) {
    this.hgContainer = this.elRef.nativeElement
   }
    ngOnChanges(changes: SimpleChanges): void { 
       this.reset()
       this.setColorMap(this.colorMap)
       this.redraw()
    }

  ngOnInit(): void {
    this.reset()
    this.setColorMap(this.colorMap)
    this.redraw()
  }

  setColorMap(key: string){
    this.props.colorMap = (this.metas.color_maps.find((c: any) => c.key === key) || {}).color_map;
  }
  
  reset = () => {
    const minY = 0;
    let maxY = 0;
    let minX = 2147483647;
    let maxX = -2147483646;

    for (const i of this.statistics){
        const band = i;
        const _band = this.metas.pie.minmax
        minX = Math.min(minX, _band.min);
        maxX = Math.max(maxX, _band.max);
        maxY = Math.max(maxY, Math.max(...band.histogram[0]));
    }

    this.rangeX = [minX, maxX];
    this.rangeY = [minY, maxY];

     this.min = Number(minX.toFixed(3))
     this.max =  Number(maxX.toFixed(3))
    ;

   /*  if (!this.state){
        this.state = st;
    }else{
        this.setState(st);
    }   */  
  }

  updateColorMap = (recreate: boolean) => {
    if (!this.colorMapElem) return;

    if (recreate){
        this.colorMapElem.select("stop").remove();

        this.props.colorMap.forEach((color: any, i:any) => {
            this.colorMapElem.append("stop")
                        .attr('offset', `${(i / (this.props.colorMap.length - 1)) * 100.0}%`)
                        .attr('stop-color', `rgb(${color.join(",")})`);
        });
    }

    const max  = this.max;
    const min = this.min
    
    const minPerc = Math.abs(min - this.rangeX[0]) / (this.rangeX[1] - this.rangeX[0]) * 100.0;
    const maxPerc = Math.abs(max - this.rangeX[0]) / (this.rangeX[1] - this.rangeX[0]) * 100.0;

    this.colorMapElem.attr('x1',`${minPerc}%`)
                     .attr('x2', `${maxPerc}%`);
  }

  redraw = () => {
    let margin = {top: 5, right: 10, bottom: 15, left: 10},
    width = this.props.width - margin.left - margin.right,
    height = 85 - margin.top - margin.bottom;

    if (this.hgContainer.firstElementChild){
        this.hgContainer.removeChild(this.hgContainer.firstElementChild);
    }

    const svgContainer = d3.select(this.hgContainer)
        .append('svg')
        .attr('class', 'histogram-container')
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom);
    
    if (this.props.colorMap){
        this.colorMapElem = svgContainer.append("defs")
                    .append("linearGradient")
                    .attr('id', 'linear')
                    .attr('x1', '0%')
                    .attr('y1', '0%')
                    .attr('x2', '100%')
                    .attr('y2', '0%');
        this.updateColorMap(true);
    }

    

    let svg: any = svgContainer.append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

    // add the x Axis
    let x = d3.scale.linear()
                .domain(this.rangeX)
                .range([0, width]);

    svg.append("g")
        .attr("class", "x axis theme-fill-primary")
        .attr("transform", "translate(0," + (height - 5) + ")")
        .call(d3.svg.axis().scale(x).tickValues(this.rangeX).orient("bottom"));

    // add the y Axis
    let y = d3.scale.linear()
            .domain(this.rangeY)
            .range([height, 0]);

    for (const  i of this.statistics){
        const band = i;
        const data = band.histogram[0].map((e: any, i: any) => {
            return [band.histogram[1][i], e];
        });

        // Make sure histogram starts and ends at 0
        // to prevent oblique looking charts
        data.unshift([data[0][0], 0]);
        data.push([data[data.length - 1][0], 0]);

        // Plot the area
        svg.append('g')
           .append("path")
           .datum(data)
           .attr("fill", !this.colorMapElem ? this.defaultBandColors[i - 1] : 'url(#linear)')
           .attr("opacity", 1 / Object.keys(this.statistics).length)
           .attr("d",  d3.svg.line()
                               .x(function(d: any) { return x(d[0]); })
                               .y(function(d: any) { return y(d[1]); })
           );
    }

    // Add sliders
    this.maxDown = false;
    this.minDown = false;
    let maxPosX: any = null;
    let minPosX: any = null;

     

    const minXStart = ((this.min - this.rangeX[0]) / (this.rangeX[1] - this.rangeX[0])) * width;
   
    const minLine = svg.append('g')
       .append('line')
       .attr('x1', minXStart)
       .attr('y1', 0)
       .attr('x2', minXStart)
       .attr('y2', height)
       .attr('class', 'theme-stroke-primary slider-line min')
       .on("mousedown", () =>
       { self.maxDown = false; self.minDown = true; })
       [0][0];
       

    const maxXStart = ((this.max - this.rangeX[0]) / (this.rangeX[1] - this.rangeX[0])) * width;
    
    const maxLine = svg.append('g')
       .append('line')
       .attr('x1', maxXStart)
       .attr('y1', 0)
       .attr('x2', maxXStart)
       .attr('y2', height)
       .attr('class', 'theme-stroke-primary slider-line max')
       .on("mousedown", () => { self.minDown = false; self.maxDown = true; })[0][0];

    const handleLeave = () => {
        this.maxDown = this.minDown = false;
        maxPosX = null;
        minPosX = null;
    };

    const self = this;

    const handleMoveMax = () => {
        if (self.maxDown){
            const mouseX = (d3.mouse(svgContainer.node()))[0];
            if (!maxPosX) maxPosX = mouseX;

            const deltaX = mouseX - maxPosX;
            const prevX = parseInt(maxLine.getAttribute('x1'));
            const newX = Math.max(Math.min(width, prevX + deltaX), parseInt(minLine.getAttribute('x1')));
            maxPosX = mouseX;
            maxLine.setAttribute('x1', newX);
            maxLine.setAttribute('x2', newX);

            if (prevX !== newX){
                this.max = (self.rangeX[0] + ((self.rangeX[1] - self.rangeX[0]) / width) * newX).toFixed(3)
                this.redraw()
                this.getRescale.emit([this.min, this.max].toString())
            }
        }
    };

    const handleMoveMin = () => {
        if (self.minDown){
            const mouseX = (d3.mouse(svgContainer.node()))[0];
            if (!minPosX) minPosX = mouseX;

            const deltaX = mouseX - minPosX;
            const prevX = parseInt(minLine.getAttribute('x1'));
            const newX = Math.max(0, Math.min(prevX + deltaX, parseInt(maxLine.getAttribute('x1'))));
            minPosX = mouseX;
            minLine.setAttribute('x1', newX);
            minLine.setAttribute('x2', newX);

            if (prevX !== newX){
                this.min = (self.rangeX[0] + ((self.rangeX[1] - self.rangeX[0]) / width) * newX).toFixed(3)
                this.redraw()
                this.getRescale.emit([this.min, this.max].toString())
            }
        }
    };

    svgContainer
        .on("mousemove",  () =>{
            handleMoveMax.apply(self)
            handleMoveMin.apply(self);
        })
        .on("mouseup", handleLeave)
        .on("mouseleave", handleLeave)
        .on("mousedown", () => {
            const mouseX = (d3.mouse(svgContainer.node()))[0];
            const maxBarX = parseInt(maxLine.getAttribute('x1')) + margin.right;
            const minBarX = parseInt(minLine.getAttribute('x1')) + margin.right;

            // Move bar closest to click
            if (Math.abs(mouseX - maxBarX) < Math.abs(mouseX - minBarX)){
                self.maxDown = true;
                maxPosX = parseInt(maxLine.getAttribute('x1')) + margin.right;
                handleMoveMax.apply(this);
            }else{
                self.minDown = true;
                minPosX = parseInt(minLine.getAttribute('x1')) + margin.right;
                handleMoveMin.apply(this);
            }
        });
  }
}
