import { setDashBoardData } from './dhashBoard.action';
import { createReducer, on } from '@ngrx/store';
import { DashBoardData } from 'src/_models/dashBoardData';


export interface SearchUserState {
    dashBoardData: DashBoardData | null
}
export const initialState: SearchUserState = { dashBoardData: null };

export const _dashBoardDataReducer = createReducer(
    initialState,
    on(setDashBoardData, (state, data) => ({ ...state, dashBoardData: data.dashBoardData}))
)

export function dashBoardDataReducer(state: any, action: any) {
    return _dashBoardDataReducer(state, action)
}

