import { OperationVolume } from './../../_models/operation';
import { NewVolumeSize } from './../../_models/newVolumeSize';
import { setNewVolumeSize, setOperationVolume, setVolumePack } from "./userOperation.action";
import{setTypeOperation} from "./userOperation.action"
import {createReducer,on} from "@ngrx/store"
import { TypeOperation } from "./../../_models/typeOperation";
import { VolumePack } from 'src/_models/volumePack';



export interface UserOperationState{
    typeOperation: TypeOperation | null
    newVolumeSize: NewVolumeSize | null
    operationVolume: OperationVolume | null
    volumePack:VolumePack| null
}
const initialState : UserOperationState = {
    typeOperation:null,
    newVolumeSize:null,
    operationVolume:null,
    volumePack:null,
}


export const _userOperationVolume = createReducer(
    initialState,
    on(setTypeOperation, (state,data)=>({...state, typeOperation:data.typeOperation})),
    on(setNewVolumeSize, (state,data)=>({...state,newVolumeSize:data.newVolumeSize})),
    on(setOperationVolume, (state, data) => ({ ...state, operationVolume: data.operationVolume})),
    on(setVolumePack, (state, data) =>({ ...state, volumePack: data.volumePack}))
)
export function userOperationVolumeReducer(state: any, action: any) {
    return _userOperationVolume(state, action) 
}






