import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public jwtHelper: JwtHelperService,
    private utils: UtilsService
  ) { }

  public isAuthenticated(): boolean {
    const token = this.utils.getStorage('token')
    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token as any);
  }
}
