import { createReducer, on } from '@ngrx/store';
import { setSearchValue } from './search.action';
export interface SearchState{
    searchString: string | null
}

export const initialState: SearchState = { searchString: null}

export const _searchReducer = createReducer(
    initialState,
    on(setSearchValue, (state, data) => ({...state, searchString: data.value }))
)
export function searchReducer(state: any, action: any) { 
    return _searchReducer(state, action)
}