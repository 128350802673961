
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/material/material.module';
import { Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHelperService } from 'src/_services/translate-helper.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment'
import { StoreModule } from '@ngrx/store';
import { reducers } from 'src/_contants/store.reducers';
import { EffectsModule } from '@ngrx/effects';
import { GraphQLModule } from './graphql.module';
import { registerLocaleData } from '@angular/common';

import localeFr from '@angular/common/locales/fr';
import{HTTP_INTERCEPTORS} from '@angular/common/http';
import { TokenInterceptor } from 'src/_services/api/interceptor.service';
import { JwtHelperService, JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';


import { SocialLoginModule,SocialAuthServiceConfig, GoogleLoginProvider, } from "angularx-social-login";


import * as Hammer from 'hammerjs';
import{NgxIntlTelInputModule} from "ngx-intl-tel-input";
import { GeometrieHelper } from './map/map/geometrie-helper';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {

  overrides = <any> {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
  providedIn:'root'
}

const dbConfig: DBConfig  = { 
  name: 'MyZoneDb',
  version: 1,
  objectStoresMeta: [{
    store: 'zoneDbStore',
    storeConfig: { keyPath: 'id', autoIncrement:true },
    storeSchema: [
         { name: 'data', keypath: 'data', options: { unique: true }}
    ]
  }]
};

registerLocaleData(localeFr);
export function playerFactory() {
  return player;
}

export function jwtOptionsFactory(storage: any) {
  return {
    tokenGetter: () => {
      return storage?.get('token');
    },
    allowedDomains: ["localhost"]
  }
}



@NgModule({
  declarations: [
    AppComponent,
  

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    LottieModule,
    HammerModule,
    NgxIntlTelInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateHelperService,
        
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production,
      stateSanitizer: () => ({ hidden: "Inclusion crashes devtools" })
     }),
    EffectsModule.forRoot([]),
    GraphQLModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory
      }
    }),
    NgxIndexedDBModule.forRoot(dbConfig),
    SocialLoginModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR'},
    {
      provide:HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi:true
    },
    JwtHelperService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },

    // CONNEXION GOOGLE FOR DEV
    {
      provide:"SocialAuthServiceConfig", 
      useValue: {
        autoLogin: true,
        providers: [
         { id:GoogleLoginProvider.PROVIDER_ID,
          provider:new GoogleLoginProvider('949883177958-0ktde1u6gvj2bsh20a58f4plcecmqbd2.apps.googleusercontent.com',{scope:'email', plugin_name:'JooLMonitorDEV'})
        },
       
        
        ]
      } as SocialAuthServiceConfig

    },
    GeometrieHelper
  ],
  bootstrap: [AppComponent] 
  
})
export class AppModule { }
