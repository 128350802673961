import { LatLng } from 'leaflet';
import { createAction, props } from "@ngrx/store";
import { CartFetch } from "src/_models/carte";
import { MapLayer } from "src/_models/mapLayer";
import { PolygoneFetch } from "src/_models/polygone";
import { RegroupementObjet } from "src/_models/regroupemenrObjet";
import { TypeActeur } from "src/_models/type.acteur";
import { TypeObjet } from "src/_models/typeObjet"; 

export const updateMap = createAction('[map Component] updateMap', props<{map:any}>())
export const setTypeActeur = createAction('[polygone Component] setTypeActeur', props<{typeActeur: TypeActeur[]}>())
export const setDraging = createAction('[polygone Component] setDraging', props<{isDrag: boolean}>())
export const setAddingPlot = createAction('[dragPlot Component] setAddingPlot', props<{isAdding: boolean}>())
export const setDragedPlot = createAction('[map FacadeService] setDragedPlot', props<{plot: object}>())
export const setPlots = createAction('[poLygone FacadeService] setPlots', props<{plots: PolygoneFetch[]}>())
export const setProjects = createAction('[poLygone Component setProjects]', props<{projects:any[]  | null}>())
export const setAudits = createAction('[map Component setAudits]', props<{audits:any[]  | null}>())
export const setHistoriqueContour = createAction('[map Component setHistoriqueContour]', props<{historiqueContour:any[]  | null}>())
export const setHistoriqueParcelise = createAction('[map Component setHistoriqueParcelise]', props<{historiqueParcelise:any[]  | null}>())


export const setPlotsShred = createAction('[poLygone FacadeService] setPlotsShared', props<{plotsShred: PolygoneFetch[]}>())
export const setPlotsShredMap = createAction('[poLygone FacadeService] setPlotsSharedMap', props<{plotsShredMap: PolygoneFetch[]}>())
export const addPlot = createAction('[polygone FacadeService] addPlot', props<{plot: PolygoneFetch}>())
export const removePlot = createAction('[PlotItem Component]', props<{plotId: string}>())
export const loadingPlot = createAction('[polygone Component] loadingPlot', props<{loading: boolean}>())
export const loadingSharePlot = createAction('[polygonePartage Component] loadingSharePlot', props<{loadingSharePlot: boolean}>())
export const setpendingPlot = createAction('[polygone Component] setpendingPlot', props<{plot: object}>())
export const setpendingProject = createAction('[project Component] setpendingProject', props<{project: object | null}>())

export const setPloting = createAction('[ Outils Component] setPloting', props<{isPloting: boolean}>())
export const setClipedPolygone = createAction('[mapFacade service] setClipedPolygone', props<{plots: any[]}>())
export const setCounting = createAction('[Outils Component]', props<{counting: boolean}>())
export const setOutils = createAction('[Outils Component] setOutils', props<{outil: string | null}>())
export const setPageUrl = createAction('[main Component] setPageUrl', props<{pageUrl:string}>())
export const setNotingGeometry = createAction('[Map Facade] setNotingGeometry', props<{geo: object}>())
export const setAnnotation = createAction ('[sideNav Facade] setAnnotation ', props<{notations: Map<string, RegroupementObjet>}>())
export const setPendingMap = createAction('[Carte Facade] setPendingMap', props<{maps: CartFetch[]}>())
export const setShareMap = createAction('[Carte Facade] setShareMap', props<{mapsShare: CartFetch[] | null}>())
export const setPendingMapIndex = createAction('[Component] setPendingMapIndex', props<{index: number | null}>())
export const setContourLineLoader = createAction('[Map Facade] setContourLineLoader', props<{loader: boolean}>())
export const addMap = createAction('[Polygone Facade]', props<{map: CartFetch}>())
export const setTypeObjet = createAction('[Map Component] setTypeObjet', props<{typeObjet: TypeObjet[]}>())
export const setTypeOperation = createAction('[Map Component] setTypeOperation', props<{typeOperation: TypeObjet[]}>())
export const addAnnotation = createAction('[Noting Component] addAnnotation', props<{note: RegroupementObjet}>())
export const setBuildingPlot = createAction('[Map Facade] setBuildingPlot', props<{plot: object | any}>())
export const setMapView = createAction('[Map Facade] setMapView', props<{view: string}>())
export const showTile = createAction('[Map Facade] showTile', props<{state: boolean}>())
export const setPendingPlotTreesNumber = createAction('[Counter Component] setPendingPlotTreesNumber', props<{counts: number}>())
export const setOpenWeather = createAction('[Map Facade] setOpenWeather', props<{openWeather: boolean}>())
export const setMapHasDtm = createAction('[Map Facade] setMapHasDtm', props<{dtm: boolean}>())
export const setMapHasDsm = createAction('[Map Facade] setMapHasDsm', props<{dsm: boolean}>())
export const setMapHasPectral = createAction('[Map Facade] setMapHasPectral',
 props<{multispectral: boolean}>())
export const setCurrentJoolProj = createAction('[Map Facade] setCurrentJoolProj', props<{joolProj: CartFetch}>())
export const showGlobalTile = createAction('[Main Component] showGlobalTile', props<{displayTile: boolean}>())
export const setContourLineError = createAction('[Map Facade] setContourLineError', props<{contourError: boolean}>())
export const setContourlineDataset = createAction('[Map Facade] setContourlineDataset', props<{dataSets: {} | undefined}>())
export const setLoadingTile = createAction('[PlotItem Component] setLoadingTile', props<{tileLoader: boolean}>())
export const setLoadingCreateContour = createAction('[contour-line-panel Component] setLoadingCreateContour', props<{loadingCreateContour: boolean}>())
export const setPendingWeather = createAction('[Map Component] setPendingWeather', props<{weather: {}}>())
export const setParcelizingLoader = createAction('[Map Facade] setParcelizingLoader', props<{loader: boolean}>())
export const setOtherTools = createAction('[map Component] setOtherTools', props<{otherTools: string | any}>())
export const setGlobalLayers = createAction('[map globalLayer] setGlobalLayers', props<{layer: MapLayer| any}>())
export const updateGlobalLayers = createAction('[map updateGlobalLayers]', props<{layers: MapLayer[]}>())
export const setCarteShare = createAction('[carte Component] setCarteShare', props<{isShared: boolean}>())
export const setResetShareCheckInput= createAction('[carte Component] setResetShareCheckInput', props<{reset: boolean}>())
export const setSelectAllMap = createAction('[carte Component] setSelectAllMap', props<{isSelectedAllMap: boolean}>())
export const setMainNav = createAction('[main Component] setMainNav', props<{mainNav: boolean}>())
export const setUserLoaction = createAction('[map Service] setUserLoaction', props<{geo: any}>())
export const setShareMapDataIsSent = createAction(' [share-map-form] setShareMapDataIsSent', props<{mapsIsShared:boolean}>())
export const setRightDraging = createAction('[ map Component]', props<{show: boolean}>())
export const setMapTileLoader = createAction('[map Component]', props<{loading: boolean}>())
export const setIndiceMarquer = createAction('[map Component]', props<{marquer: LatLng}>())
export const setPendingTree = createAction('[map Component ]', props<{geo: any}>())
export const setOptiRendement = createAction('[counter Component]', props<{rend: any[]}>())
 





