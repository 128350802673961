import { LatLng } from 'leaflet';
import { state } from "@angular/animations";
import { createReducer, on } from "@ngrx/store";
import { CartFetch } from "src/_models/carte";
import { MapLayer } from "src/_models/mapLayer";
import { Polygone, PolygoneFetch } from "src/_models/polygone";
import { RegroupementObjet } from "src/_models/regroupemenrObjet";
import { TypeActeur } from "src/_models/type.acteur";
import { TypeObjet } from "src/_models/typeObjet";

import { addAnnotation, addPlot, loadingPlot, setAddingPlot, setAnnotation, setBuildingPlot, setClipedPolygone, setContourLineLoader, setCounting, setDragedPlot, setDraging, setNotingGeometry, setOutils, setPendingMap, setPendingMapIndex, setpendingPlot, setPloting, setPlots,setPlotsShred, setTypeActeur, setTypeObjet, setTypeOperation, updateMap, setMapView, removePlot, showTile, setPendingPlotTreesNumber, setOpenWeather, setMapHasDtm, setMapHasDsm, showGlobalTile, setContourlineDataset, setPendingWeather, setMapHasPectral, setCurrentJoolProj, setParcelizingLoader, setOtherTools, setGlobalLayers, updateGlobalLayers, setContourLineError, setCarteShare,setPlotsShredMap,setSelectAllMap,setLoadingTile,setShareMapDataIsSent, setShareMap, setResetShareCheckInput, setLoadingCreateContour, loadingSharePlot, setRightDraging, setMapTileLoader, setIndiceMarquer, setProjects, setpendingProject, setUserLoaction, setAudits, setPendingTree, setOptiRendement, setPageUrl, setHistoriqueContour, setHistoriqueParcelise,} from "./map.actions";

declare var turf: any;
export interface MapState{
  map: any | any,
  typeActeur: TypeActeur[] | any,
  draging: boolean| any,
  ploting: boolean| any,
  addingPlot: boolean| any,
  newPlot: boolean| any,
  dragedPlot: any| any,
  actors: PolygoneFetch[]| any,
  projects:any[] | null,
  audits:any[] | null,
  historiqueContour:any[] | null,
  historiqueParcelise:any[] | null
  actorsShared : PolygoneFetch[]| any,
  actorsSharedMap : PolygoneFetch[]| any,
  loadingPlot: boolean| any,
  pendingPlot: PolygoneFetch| any,
  pendingProject:any
  clipedPlolygones: any[]| any,
  counting: boolean | any,
  outil: string | any,
  pageUrl:string | any
  noteGeometry: object | any,
  annotations: Map<string, RegroupementObjet> | any,
  pendingMaps: CartFetch | any
  pendingMapsShare:CartFetch | any
  pendingMapIndex: number | any,
  loadingContourLine: boolean,
  typeObjet: TypeObjet[] | any,
  typeOperation: any,
  buildingPlot: object | any,
  mapView: string | any,
  displayTile: boolean | any,
  pendingPlotTreesNumber: number | any,
  openWeather: boolean | any,
  hasDtm: boolean | any,
  hasDsm: boolean | any,
  hasMultiSpectral: boolean | any,
  showGlobalTile: boolean | any,
  setContourlineDataset: {} | any,
  pendingWeather: {} | any,
  eframeView: boolean | any,
  joolProj: CartFetch | any,
  parcelizingLoader: boolean | any,
  otherTools: string | any,
  layers: MapLayer[] | any,
  contourLineError: boolean,
  sharedCarte: boolean | any,
  selectAllMap: boolean | any,
  mapsIsShared:boolean | any,
  reset:boolean | any,
  tileLoader: boolean | any
  loadingCreateContour:boolean | any
  loadingSharePlot: boolean | any,
  showRightDraging: boolean | any,  
  mapTileLoader: boolean | any,
  currentUser: any,
  indiceMarquer: LatLng | any,
  pendingTree: any,
  optiRendement: any[] | any
}
const initialState:MapState = {
  map: null,
  typeActeur: [],
  draging: null,
  ploting: null,
  addingPlot: null,
  newPlot: null,
  dragedPlot: null,
  actors: [],
  actorsShared:[],
  actorsSharedMap:[],
  loadingPlot: null,
  pendingPlot: null,
  pendingProject:null,
  clipedPlolygones: null,
  counting: null,
  outil: null,
  pageUrl:null,
  noteGeometry: null,
  annotations: null,
  pendingMaps: null,
  pendingMapsShare :null,
  pendingMapIndex: null,
  loadingContourLine: false,
  typeObjet:[],
  typeOperation: [],
  buildingPlot: null,
  mapView: null,
  displayTile: false,
  pendingPlotTreesNumber: 0,
  openWeather: false,
  hasDsm: false,
  hasDtm: false,
  hasMultiSpectral: false,
  showGlobalTile: false,
  setContourlineDataset: null,
  pendingWeather: null,
  eframeView: false,
  joolProj: null,
  parcelizingLoader: false,
  otherTools: null,
  layers: [],
  contourLineError: false,
  sharedCarte:false,
  selectAllMap:false,
  mapsIsShared:false,
  reset:false,
  tileLoader: false,
  loadingCreateContour:false,
  loadingSharePlot: false,
  showRightDraging: false,
  mapTileLoader:  false,
  indiceMarquer: null,
  projects:[],
  audits:[],
  historiqueContour:[],
  historiqueParcelise:[],
  currentUser: null,
  pendingTree: null,
  optiRendement: null,

}

export const _mapReducer = createReducer(
    initialState,
  on(setTypeActeur, (state, _typeActeur) => ({...state, typeActeur: _typeActeur.typeActeur})),
  on(setDraging, (state, _isDraging) => ({...state, draging: _isDraging.isDrag})),
  on(setAddingPlot, (state, _newPlot) => ({...state, newPlot: _newPlot.isAdding})),
  on(setDragedPlot, (state, _plot) => ({...state, dragedPlot: _plot.plot})),
  on(setPlots, (state, plots) => ({...state, actors: plots.plots})),
  on(setProjects,(state,_projects) => ({...state,projects:_projects.projects})),
  on(setAudits, (state, _audit)=>({...state, audits:_audit.audits})),
  on(setHistoriqueContour,(state, _historiqueContour)=>({...state, historiqueContour:_historiqueContour.historiqueContour})),
  on(setHistoriqueParcelise, (state, _historiqueParcelise)=>({...state, historiqueParcelise:_historiqueParcelise.historiqueParcelise})),
  on(setPlotsShred, (state, plotsShared) => ({...state, actorsShared: plotsShared.plotsShred})),
  on(setPlotsShredMap, (state, plotsSharedMap) => ({...state, actorsSharedMap: plotsSharedMap.plotsShredMap})),

  on(addPlot, (state, _plots) => {
    let newState = JSON.parse(JSON.stringify(state))
    newState.actors.unshift(_plots.plot)
    return {...state, actors: newState.actors}
  }),
  on(removePlot, (state, {plotId}) => {
    let oldState:any = JSON.parse(JSON.stringify(state)).actors
    let plotIndex = oldState.findIndex(
      (plot: any) => plot.id === plotId
    )
    oldState.splice(plotIndex, 1)
    return ({...state, actors: oldState})
  }),
  on(loadingPlot, (state, loading) => ({...state, loadingPlot: loading.loading})),
  on(loadingSharePlot, (state, loading) => ({...state, loadingSharePlot: loading.loadingSharePlot})),
  on(setpendingPlot, (state, plot) => ({...state, pendingPlot: plot.plot})),
  on(setpendingProject, (state, project) => ({...state, pendingProject: project.project})),

  on(setPloting, (state, _ploting) => ({...state, ploting: _ploting.isPloting})),
  on(setClipedPolygone, (state, _plots) => {
     if(_plots.plots.length){
       let newPlotValue = _plots.plots.map((ref) => new Polygone('', '', '', ref, Number((turf.area(ref) * 0.0001).toFixed(2),),'{}')) 
      
       return ({...state, clipedPlolygones: newPlotValue })
     } else {
      return ({...state, clipedPlolygones: _plots.plots })
     }
  }),
  on(setCounting, (state, count) => ({...state, counting: count.counting})), 
  on(setOutils, (state, _outil) => {
    return ({...state, outil: _outil.outil})
  }),
  on(setPageUrl,(state,_pageUrl)=>({...state, pageUrl:_pageUrl.pageUrl})),
  on(setNotingGeometry, (state, _geo) => ({...state, noteGeometry: _geo.geo})),
  on(setAnnotation, (state, notes) => {
    return ({...state, annotations: notes.notations})
  }),
  on(addAnnotation, (state, annotattion) => { 
    const initial = state.annotations
    initial.set(annotattion.note.id, annotattion.note)
    return ({...state, annotations: initial, noteGeometry: null})
  }),
  on(setPendingMap, (state, maps) => ({...state, pendingMaps: maps.maps})),
  on(setShareMap, (state, mapsShare) => ({...state, pendingMapsShare: mapsShare.mapsShare})),
  on(
    setPendingMapIndex, (state, _index) => ({...state, pendingMapIndex: _index.index})
  ),
  on (setContourLineLoader, (state, loader) => ({...state, loadingContourLine: loader.loader})),
  on(setTypeOperation, (state, operations) => {
    return ({...state, typeOperation: operations.typeOperation})
  }),

  on(setTypeObjet, (state, stypeObjet) => {
    return ({...state, typeObjet: stypeObjet.typeObjet })
  }),
  on(setBuildingPlot, (state, builDingPlot) => {
      return ({...state, buildingPlot: builDingPlot.plot})
  }),
  on(setMapView, (state, view) => ({...state, mapView: view.view})),
  on(showTile, (state, tileState) => ({...state, displayTile: tileState.state})),
  on(setPendingPlotTreesNumber, (state, treesNumber) => ({
    ...state, pendingPlotTreesNumber: treesNumber.counts
  })),
  on(setOpenWeather, (state, weather) => ({...state, openWeather: !state.openWeather})),
  on(setMapHasDtm, (state, dtm) => ({...state, hasDtm: dtm.dtm})),
  on(setMapHasDsm, (state, dsm) => ({...state, hasDsm: dsm.dsm})),
  on(setMapHasPectral, (state, multiSpectral) => ({...state, hasMultiSpectral: multiSpectral.multispectral})),
  on(showGlobalTile, (state, globalTile) => ({...state, showGlobalTile: globalTile.displayTile })),
  on(setContourlineDataset, (state, dataSets) => ({...state, setContourlineDataset: dataSets.dataSets })),
  on(setPendingWeather, (state, weather) =>({...state, pendingWeather: weather.weather })),
  on(setCurrentJoolProj, (state, joolProj) => ({...state, joolProj: joolProj.joolProj})),
  on(setParcelizingLoader, (state, parcelizeLoader) => ({...state, parcelizingLoader: parcelizeLoader.loader})),
  on(setOtherTools, (state, otherTools) => ({...state, otherTools: otherTools.otherTools})),
  on(setContourLineError, (state, contourLineError) => ({...state, contourLineError: contourLineError.contourError})),
  on(setGlobalLayers, (state, _layer) => {
    let old_layer = JSON.parse(JSON.stringify(state.layers))
    if (old_layer.filter(
      (layer: MapLayer) => layer.mapId === _layer.layer.mapId
    ).length === 0){
       old_layer.push(_layer.layer)
    }
    return ({...state, layers: old_layer}) 
  }),
  on(updateGlobalLayers, (state, layers) => ({...state, layers: layers.layers})),
  on(setCarteShare, (state, _sharedCarte) => ({...state,  sharedCarte: _sharedCarte.isShared})),
  on(setSelectAllMap, (state, _selectAllMap) => ({...state,  selectAllMap: _selectAllMap.isSelectedAllMap})),
  on(setShareMapDataIsSent,(state,_mapsIsShared)=>({...state,mapsIsShared:_mapsIsShared.mapsIsShared})),
  on(setResetShareCheckInput,(state,_reset)=>({...state,reset:_reset.reset})),
  on(setLoadingTile, (state, loadingTile) =>({...state, tileLoader: loadingTile.tileLoader})),
  on(setLoadingCreateContour,(state,_loadingCreateContour)=>({...state, loadingCreateContour:_loadingCreateContour.loadingCreateContour})),
  on(setRightDraging, (state, rightDrag) => ({...state, showRightDraging: rightDrag.show})),
  on(setMapTileLoader, (state, tileLoader) =>{
    console.log('updating tile loder', tileLoader)
    if(tileLoader.loading !== undefined){
      return ({...state, mapTileLoader: tileLoader.loading})
    }else{
      return state
    }
  }),
  on(setIndiceMarquer, (state, data) => ({...state, indiceMarquer: data.marquer})),
  on(setUserLoaction, (state, data) => ({...state, currentUser: data.geo })),
  on(setPendingTree, (state, data) => ({...state, pendingTree: data.geo })),
  on(setOptiRendement, (state, data) => ({...state, optiRendement: data.rend})),

)
export function mapReducer(state: any, action: any) {
    return _mapReducer(state, action) 
}
