import { createReducer, on } from "@ngrx/store";
import { setHeader } from "./header.action";
export const initialState:{title: string | null}= {title: ''};


const _setHeaderReducer = createReducer(
    initialState,
    on(setHeader, (state, _title) => ({...state, title: _title.title})),
    
)

export function  setHeaderReducer(state: any, action: any) {
    return _setHeaderReducer(state, action)
}