
import { createReducer, on } from '@ngrx/store';
import {  UserProfil } from './../../_models/userProfil'; 
import { setUserProfil } from './seachUser.action';

export interface SearchUserState{
    userProfil: UserProfil | null
    
}
export const initialState: SearchUserState = { userProfil:null};

export const _userProfilReducer = createReducer(
    initialState,
    on(setUserProfil, (state, data)=>({...state, userProfil:data.userProfil})),
    
)

export function UserProfilReducer(state:any,action:any){
    return _userProfilReducer(state, action)
}
