import { createReducer, on } from "@ngrx/store";
import { GuestNoConfirmList, GuestUserList } from "src/_models/guestlist";
import { addNoConfirmUser, setGuestList, setGuestNoConfirmList } from "./guestList.action";

export interface GuestListState{
    guestList: GuestUserList[] | any,
    guestNoConfirmList:GuestNoConfirmList[]
    
  }
  const initialState:GuestListState = {
    guestList: [],
    guestNoConfirmList:[]
  }

  export const _guestListReducer = createReducer(
    initialState,
  on(setGuestList, (state, _guestList) => ({...state, guestList: _guestList.guestList})),
  on(setGuestNoConfirmList,(state ,_guestNoConfirmList) => ({...state, guestNoConfirmList: _guestNoConfirmList.guestNoConfirmList})),
  on(addNoConfirmUser, (state, userGuestNoconfirm) => {
    let oldList = JSON.parse(JSON.stringify(state.guestNoConfirmList))
    oldList.unshift(userGuestNoconfirm.user)
    return({...state, guestNoConfirmList: oldList})
  })
  )


  export function guestListReducer(state: any, action: any) {
    return _guestListReducer(state, action)
}