import { createReducer, on } from "@ngrx/store";
import { AppStores } from "src/_enums/stores.enum";
import { setExpandBottomNav, setHelpert, setLoader, setPlotNextPage, setZoneNextProtege } from "./app.action";


export interface AppState {
    loading: boolean,
    plot_next_page: number | null,
    zone_protege_next_page: number | null,
    expandBottomNav: boolean,
    helper: string | null
}

export const initialState: AppState = {
    loading: false,
    plot_next_page: 0,
    zone_protege_next_page: 0,
    expandBottomNav: true,
    helper: null

}


export const _appReducer = createReducer(
   initialState,
   on(setLoader, (state: any, _loading: { load: any; }) => ({...state, loading: _loading.load })),
   on(setPlotNextPage, (state: any, {page}: any) => ({...state, plot_next_page: page})),
   on(setZoneNextProtege, (state: any, {page}: any) => ({...state, zone_protege_next_page: page})),
   on(setExpandBottomNav, (state: any, next) => {
    let currentAppState = JSON.parse(JSON.stringify(state))
    return ({...state, expandBottomNav: !currentAppState.expandBottomNav})
   }),
   
   on(
       setHelpert, (state, help) => ({...state, helper: help.help})
   )
)

export function appReducer(state: AppState | undefined, action: any) {
    return _appReducer(state, action)
}