//export const ip = "http://192.168.1.45"
// export const ip = 'http://109.205.183.12:8000'
// export const ip = 'http://209.94.56.90'
// export const ip= 'http://192.168.1.45'
// export const fileServer = 'https://prod.sf.jool-monitor.com'
// export const ip = 'http://185.177.116.124'
// export const ip = 'https://prod.back.jool-monitor.com'
//export const ipServer = 'https://prod.sf.jool-monitor.com'
// Prod
// export const ipServer = 'http://185.177.116.124'
// export const ip = 'http://185.177.116.124'
/* export const environment = {
  production: false,
  mapViewUrl: 'https://online-process.jool-tech.com/public/task/',
  uri: ip + '/graphql/', 
  treesUrl: ip + ':8012/graphql/',
  profile_uri: ip + '/media/',
  fileUploadUrl: fileServer  + '/upload/',

  fileDownloadUrl: fileServer+ '/',  
  fileDownloadUrlFile: fileServer + '/files/',
  file_server: fileServer + '/', 
  kmlUrl: fileServer  + '/',
  fs_server_token: 'W&6jnbYKw%6H3CRQA$zQ=ue^g5%VddUSptGA8R!nrwjdk3*NcQeD6S7&dy-m7u-pad?6kn_@mDQ28MCbD@+-4E+RUBXu#EKb9G5yJ!vT4N_yRjQX3w$Cf@jV=pUEaCr_',
  perPage: 25,
  compress: true,
  linkedInCredentials : {
  clientId: "78mlpre94sl27j",
  redirectUrl: "https://dev.jool-monitor.com/",
  scope:"r_emailaddress,r_liteprofile,w_member_social",
  }
} */

// prod
export const ip = 'https://back.prod.jool-monitor.com'
//  export const ip = "http://192.168.1.29:8000"
export const file_server_ip = 'https://fs.prod.jool-monitor.com'
//  export const file_server_ip = 'http://192.168.1.29:8008'
export const trees = 'https://prod.comptage.jool-monitor.com'

   // dev 
  // export const ip = 'https://back.dev.jool-monitor.com'
  // export const file_server_ip = 'https://fs.dev.jool-monitor.com'
  // export const trees = 'http://185.177.116.124:8012'
  
  export const environment = {
    production: false,
    mapViewUrl: 'https://online-process.jool-tech.com/public/task/',
    uri: ip + '/graphql/',  
    treesUrl: trees + '/graphql',
    profile_uri: ip + '/',
    fileUploadUrl: file_server_ip + '/upload/',
    fileDownloadUrl: file_server_ip + '/',
    fileDownloadUrlFile: file_server_ip + '/files/',
    file_server: file_server_ip + '/',
    kmlUrl: file_server_ip  + '/',
    fs_server_token: 'W&6jnbYKw%6H3CRQA$zQ=ue^g5%VddUSptGA8R!nrwjdk3*NcQeD6S7&dy-m7u-pad?6kn_@mDQ28MCbD@+-4E+RUBXu#EKb9G5yJ!vT4N_yRjQX3w$Cf@jV=pUEaCr_',
    perPage: 25,
    compress: true,

     linkedInCredentials : {
    clientId: "78gqz0hwdnmczu",
    redirectUrl: "https://jool-monitor.com/",
    scope:"r_emailaddress,r_liteprofile,w_member_social",
    }
  }
